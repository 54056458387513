import { GiSittingDog, GiCat } from 'react-icons/gi';

import Carousel from '../Carousel';

import Item1 from '../../static/images/chaqueta2.jpeg';
import Item2 from '../../static/images/chaqueta3.jpeg';
import Item3 from '../../static/images/chaqueta4.jpeg';
import Item4 from '../../static/images/cojin6.jpeg';
import Item5 from '../../static/images/cojin7.jpeg';
import Item6 from '../../static/images/cojin8.jpeg';
import Item7 from '../../static/images/cobija2.jpeg';
import Item8 from '../../static/images/cobija3.jpeg';
import Item9 from '../../static/images/placa1.jpeg';
import Item10 from '../../static/images/saco1.jpeg';
import Item11 from '../../static/images/saco2.jpeg';
import Item12 from '../../static/images/saco3.jpeg';

import Card from '../Card';

import appStyles from '../../App.module.css';
import initStyles from './Init.module.css';

function Products() {
  const title = (
    <>
      <GiSittingDog />
      <GiCat className={initStyles.titleIcon} />
      Productos Destacados.
      <b className={appStyles.boldEmptySection} />
    </>
  );

  return (
    <Carousel title={title} maxItems={5}>
      <Card source={Item1} />
      <Card source={Item2} />
      <Card source={Item3} />
      <Card source={Item4} />
      <Card source={Item5} />
      <Card source={Item6} />
      <Card source={Item7} />
      <Card source={Item8} />
      <Card source={Item9} />
      <Card source={Item10} />
      <Card source={Item11} />
      <Card source={Item12} />
    </Carousel>
  );
}

export default Products;
