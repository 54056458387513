import { GiBandageRoll } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/panoleta2.jpeg';

function Kerchiefs() {
  const title = (
    <>
      <GiBandageRoll />
      Pañoletas.
    </>
  );
  const kerchiefs = [Item1, Item1, Item1];

  return (
    <CustomCarousel title={title} id="kerchiefs" items={kerchiefs} />
  );
}

export default Kerchiefs;
