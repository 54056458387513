import { Outlet } from 'react-router-dom';

import Banner from './banner/Banner';
import Header from './header/Header';
import Footer from './footer/Footer';

import styles from '../App.module.css';

function Main() {
  return (
    <div>
      <Banner />
      <Header />
      <div className={styles.backgroundContainer}>
        <Outlet />
      </div>
      <Footer />
    </div>

  );
}

export default Main;
