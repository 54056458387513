import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from '../App.module.css';

type GridCarouselArgs = {
  carouselImages: string[]
  sideImage1: string
  sideImage2: string
};

function GridCarousel({ carouselImages, sideImage1, sideImage2 }: GridCarouselArgs) {
  return (
    <div className={styles.gridContainer}>
      <div className={styles.gridItem1}>
        <Carousel autoPlay showThumbs={false} infiniteLoop emulateTouch showStatus={false} interval={6000}>
          {
              carouselImages.map((image) => (<img src={image} />))
            }
        </Carousel>
      </div>
      <div className={styles.gridItem2}>
        <img style={{ maxWidth: '100%', maxHeight: '100%', width: '350px' }} src={sideImage1} />
      </div>
      <div className={styles.gridItem3}>
        <img style={{ maxWidth: '100%', maxHeight: '100%', width: '350px' }} src={sideImage2} />
      </div>
    </div>
  );
}

export default GridCarousel;
