import { FaVest } from 'react-icons/fa';

import ClothesCarousel from './ClothesCarousel';

import Item1 from '../../static/images/chaleco1.jpeg';

function Vests() {
  const title = (
    <>
      <FaVest />
      Chalecos.
    </>
  );

  const vests = [Item1, Item1, Item1];

  return (
    <ClothesCarousel title={title} id="vests" items={vests} />
  );
}

export default Vests;
