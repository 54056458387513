import ItemsPanel from '../ItemsPanel';

import Jackets from './Jackets';
import Sweaters from './Sweaters';
import Vests from './Vests';

function Clothes() {
  return (
    <ItemsPanel>
      <Jackets />
      <Vests />
      <Sweaters />
    </ItemsPanel>
  );
}

export default Clothes;
