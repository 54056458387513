import { FaQuestionCircle } from 'react-icons/fa';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/placa1.jpeg';

function Plates() {
  const title = (
    <>
      <FaQuestionCircle />
      Placas.
    </>
  );
  const plates = [Item1, Item1, Item1];

  return (
    <CustomCarousel title={title} id="plates" items={plates} />
  );
}

export default Plates;
