import GridCarousel from '../GridCarousel';

import carousel1 from '../../static/images/local1.jpg';
import carousel2 from '../../static/images/local4.jpg';
import carousel3 from '../../static/images/local7.jpg';

import appStyles from '../../App.module.css';

function Store() {
  const carouselImages = [
    carousel1,
    carousel2,
    carousel3,
  ];

  return (
    <div className={appStyles.container}>
      <GridCarousel carouselImages={carouselImages} sideImage1={carousel1} sideImage2={carousel2} />
    </div>
  );
}

export default Store;
