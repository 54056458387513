import appStyles from '../App.module.css';

function ShoppingCart() {
  return (
    <div className={appStyles.container}>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>
      <h2>Your cart is empty</h2>

    </div>
  );
}

export default ShoppingCart;
