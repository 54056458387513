import { GiPillow } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item2 from '../../static/images/cojin2.jpeg';
import Item3 from '../../static/images/cojin3.jpeg';
import Item5 from '../../static/images/cojin5.jpeg';
import Item6 from '../../static/images/cojin6.jpeg';
import Item7 from '../../static/images/cojin7.jpeg';
import Item8 from '../../static/images/cojin8.jpeg';
import Item9 from '../../static/images/cojin9.jpeg';
import Item10 from '../../static/images/cojin10.jpeg';
import Item11 from '../../static/images/cojin11.jpeg';

function Cushions() {
  const title = (
    <>
      <GiPillow />
      Cojines.
    </>
  );
  const cushions = [
    Item2,
    Item3,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Item11,
  ];

  return (
    <CustomCarousel title={title} id="cushions" items={cushions} />
  );
}

export default Cushions;
