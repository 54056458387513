import { GiBackpack } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/tula1.jpeg';
import Item2 from '../../static/images/tula2.jpeg';
import Item3 from '../../static/images/tula3.jpeg';
import Item4 from '../../static/images/tula4.jpeg';

function Backpacks() {
  const title = (
    <>
      <GiBackpack />
      Tulas.
    </>
  );
  const backpacks = [
    Item1,
    Item2,
    Item3,
    Item4,
  ];

  return (
    <CustomCarousel title={title} id="backpacks" items={backpacks} />
  );
}

export default Backpacks;
