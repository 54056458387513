import PaymentMethods from './PaymentMethods';
import FooterNav from './FooterNav';

import appStyles from '../../App.module.css';
import footerStyles from './Footer.module.css';

function Footer() {
  return (
    <div className={footerStyles.footerContainer}>
      {/* <PaymentMethods /> Descomentar para agregar metodos de pago */}
      <hr className={appStyles.container} />
      <FooterNav />
    </div>
  );
}

export default Footer;
