import { GiMonclerJacket } from 'react-icons/gi';

import ClothesCarousel from './ClothesCarousel';

import Item1 from '../../static/images/saco1.jpeg';
import Item2 from '../../static/images/saco2.jpeg';
import Item3 from '../../static/images/saco3.jpeg';
import Item4 from '../../static/images/saco4.jpeg';

function Sweaters() {
  const title = (
    <>
      <GiMonclerJacket />
      Sacos.
    </>
  );

  const sweaters = [
    Item1,
    Item2,
    Item3,
    Item4,
  ];

  return (
    <ClothesCarousel title={title} id="sweaters" items={sweaters} />
  );
}

export default Sweaters;
