import GridCarousel from '../GridCarousel';

import carousel1 from '../../static/images/local1.jpg';
import carousel2 from '../../static/images/local4.jpg';
import carousel3 from '../../static/images/local7.jpg';

function MainCarousel() {
  const carouselImages = [
    carousel1,
    carousel2,
    carousel3,
  ];

  return (
    <GridCarousel carouselImages={carouselImages} sideImage1={carousel1} sideImage2={carousel2} />
  );
}

export default MainCarousel;
