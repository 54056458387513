import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import SocialIcons from '../SocialIcons';

import appStyles from '../../App.module.css';
import footerStyles from './Footer.module.css';

function FooterNav() {
  return (
    <div className={`${footerStyles.footerNav} ${appStyles.container}`}>
      <Link className={`${footerStyles.footerNavItem} ${appStyles.flexLeft} ${appStyles.link}`} to="team">
        Nuestro Equipo
      </Link>
      <Link className={`${footerStyles.footerNavItem} ${appStyles.flexCenter} ${appStyles.link}`} to="location">
        Ubicación
      </Link>
      <a
        className={`${footerStyles.footerNavItem} ${appStyles.flexCenter} ${appStyles.link}`}
        href="https://wa.me/+573118681435"
        rel="noreferrer"
        target="_blank"
      >
        <div className={`${footerStyles.whatsappSection} ${appStyles.icons}`}>
          <FaWhatsapp />
          {' '}
          Whatsapp
          {' '}
        </div>
      </a>
      <SocialIcons containerClassName={`${footerStyles.footerNavItem} ${appStyles.flexRight}`} iconClassName={`${appStyles.icons} ${appStyles.link}`} />
    </div>
  );
}

export default FooterNav;
