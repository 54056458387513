import { MouseEvent, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  PopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import appStyles from '../App.module.css';

type DropDownArgs = {
  title: string | JSX.Element,
  className:string
  children?: JSX.Element | JSX.Element[]
  popupState: PopupState
  position? : number | 'right' | 'center' | 'left'
};

function DropDown({
  title, children, className, popupState, position = 'left',
}: DropDownArgs) {
  return (
    <div className={className}>
      <div className={`${appStyles.link} ${appStyles.verticalAlign}`} {...bindTrigger(popupState)}>
        {title}
      </div>
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          style: {
            backgroundColor: 'white',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
      >
        {children}
      </Menu>
    </div>
  );
}

export default DropDown;
