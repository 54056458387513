import { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import appStyles from '../App.module.css';

type CardArgs = {
  source: string,
  children?: JSX.Element | JSX.Element[],
};

interface state {
  height: number | undefined | string,
  width: number | undefined | string
}

function Card({ source, children }: CardArgs) {
  const initState = { height: 400, width: 300 };
  const [state, setState] = useState<state>(initState);

  const handleAfterLoad = () => {
    const state = {
      height: 'auto',
      width: 'auto',
    };
    setState(state);
  };

  return (
    <div className={appStyles.cardContainer}>
      <img className={appStyles.card} src={source} height={state.height} width={state.width} onLoad={handleAfterLoad} />
      {children}
    </div>
  );
}

export default Card;
