import { GiBed } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/cobija2.jpeg';
import Item2 from '../../static/images/cobija3.jpeg';
import Item3 from '../../static/images/cobija4.jpeg';
import Item4 from '../../static/images/cobija5.jpeg';
import Item5 from '../../static/images/cobija6.jpeg';
import Item6 from '../../static/images/cobija7.jpeg';

function Blankets() {
  const title = (
    <>
      <GiBed />
      Cobijas.
    </>
  );
  const blankets = [
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
  ];

  return (
    <CustomCarousel title={title} id="blankets" items={blankets} />
  );
}

export default Blankets;
