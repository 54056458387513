import ItemsPanel from '../ItemsPanel';

import Aprons from './Aprons';
import Backpacks from './Backpacks';
import Blankets from './Blankets';
import Cushions from './Cushions';
import Kerchiefs from './Kerchiefs';
import Plates from './Plates';

function Custom() {
  return (
    <ItemsPanel>
      <Cushions />
      <Blankets />
      <Plates />
      <Aprons />
      <Backpacks />
      <Kerchiefs />
    </ItemsPanel>
  );
}

export default Custom;
