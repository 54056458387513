import { GiMonclerJacket } from 'react-icons/gi';

import ClothesCarousel from './ClothesCarousel';

import Item1 from '../../static/images/chaqueta2.jpeg';
import Item2 from '../../static/images/chaqueta3.jpeg';
import Item3 from '../../static/images/chaqueta4.jpeg';
import Item4 from '../../static/images/chaqueta5.jpeg';
import Item5 from '../../static/images/chaqueta6.jpeg';
import Item6 from '../../static/images/chaqueta7.jpeg';
import Item7 from '../../static/images/chaqueta8.jpeg';

function Jackets() {
  const title = (
    <>
      <GiMonclerJacket />
      Chaquetas.
    </>
  );
  const jackets = [
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
  ];

  return (
    <ClothesCarousel title={title} id="jackets" items={jackets} />
  );
}

export default Jackets;
