import MainCarousel from './MainCarousel';
import Products from './Products';

import appStyles from '../../App.module.css';

function Init() {
  return (
    <div className={appStyles.container}>
      <MainCarousel />
      <Products />
    </div>
  );
}

export default Init;
