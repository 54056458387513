import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useMediaQuery } from 'react-responsive';

import Carousel from '../Carousel';
import Card from '../Card';

__dirname;

type ClothesCarouselArgs = {
  items: string[],
  title: string | JSX.Element,
  id: string,
};

function ClothesCarousel({ title, items, id }: ClothesCarouselArgs) {
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 464 });

  return (
    <LazyLoadComponent>
      <Carousel title={title} id={id}>
        {items.map((item) => (
          <Card source={item} />
        ))}
      </Carousel>
    </LazyLoadComponent>
  );
}

export default ClothesCarousel;
