import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/delantal1.jpeg';

function Aprons() {
  const title = (
    <>
      Delantales.
    </>
  );
  const aprons = [Item1, Item1, Item1];

  return (
    <CustomCarousel title={title} id="aprons" items={aprons} />
  );
}

export default Aprons;
