import { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { Carousel as SingleCarousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';

import 'react-multi-carousel/lib/styles.css';
import appStyles from '../App.module.css';

type ClothesCarouselArgs = {
  title: string | JSX.Element,
  id?: string,
  maxItems?: number,
  children?: JSX.Element | JSX.Element[]
};

function ClothesCarousel({
  title, id, children, maxItems = 3,
}: ClothesCarouselArgs) {
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 464 });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: maxItems,
      paritialVisibilityGutter: 80,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
  };

  let deviceType = 'desktop';

  if (isMobile) {
    deviceType = 'tablet';
  } else if (isTablet) {
    deviceType = 'mobile';
  }

  return (
    <>
      <h3 id={id} className={appStyles.titleSection}>
        {title}
        <b className={appStyles.boldEmptySection} />
      </h3>
      <Carousel
        ssr
        infinite
        deviceType={deviceType}
        itemClass={appStyles.itemCarouselClass}
        sliderClass={appStyles.itemCarouselClass}
        autoPlay={!isMobile}
        autoPlaySpeed={5000}
        responsive={responsive}
      >
        {children}
      </Carousel>
    </>
  );
}

export default ClothesCarousel;
