import appStyles from '../App.module.css';

import banner from '../static/images/location-banner.jpeg';

function Location() {
  return (
    <>
      <div className={appStyles.bannerImage} />
      <div className={appStyles.container}>

        <ul className={appStyles.locationList}>
          <li>
            <b>Dirección:</b>
            {' '}
            <span>Ac. 26 Sur #38B - 54, Bogotá</span>
          </li>
          <li>
            <b>Lunes a Viernes:</b>
            {' '}
            <span>10:00 am a 6:00pm</span>
          </li>
          <li>
            <b>Sabados:</b>
            {' '}
            <span>10:00 am a 5:30pm</span>
          </li>
          <li>
            <b>Telefono:</b>
            {' '}
            <span>3118681435</span>
          </li>
        </ul>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.9667839297126!2d-74.11924708485631!3d4.599972543802599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f991bae9d2b3f%3A0x69f4f09962607dd8!2sPeludos!5e0!3m2!1sen!2sco!4v1645475038201!5m2!1sen!2sco"
          width="600"
          height="450"
          allowFullScreen={false}
          loading="lazy"
        />
      </div>
    </>
  );
}

export default Location;
