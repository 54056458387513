import {
  Routes,
  Route,
} from 'react-router-dom';

import Clothes from './components/clothes/Clothes';
import Custom from './components/custom/Custom';
import Init from './components/init/Init';
import Location from './components/Location';
import Main from './components/Main';
import Spa from './components/spa/Spa';
import ShoppingCart from './components/ShoppingCart';
import Store from './components/store/Store';
import Team from './components/Team';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="" element={<Init />} />
          <Route path="clothes" element={<Clothes />} />
          <Route path="custom" element={<Custom />} />
          <Route path="location" element={<Location />} />
          <Route path="spa" element={<Spa />} />
          <Route path="shopping-cart" element={<ShoppingCart />} />
          <Route path="store" element={<Store />} />
          <Route path="team" element={<Team />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
